/* General settings */
p {
  font-size: 1.5rem;
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}

.content {
  flex-grow: 1;
}

.page-content {
  width: 80%;
  margin: auto;
  margin-top: 15%;
  margin-bottom: 15%;
  line-height: 2rem;
  letter-spacing: 0.08rem;
}

.page-content h1 {
  text-align: center;
}

.animated-btn,
.animated-link {
  font-family: "Roboto Light";
  background-color: var(--colour-pink);
  color: var(--colour-white);
  border-radius: 50px;
  font-size: 1.5rem;
  width: 85%;
  display: block;
  margin: auto;
  padding: 8%;
  transition: all 0.5s;
  cursor: pointer;
}

.animated-btn span,
.animated-link span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.animated-btn span:after,
.animated-link span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.animated-btn:hover span,
.animated-link:hover span {
  padding-right: 25px;
}

.animated-btn:hover span:after,
.animated-link:hover span:after {
  opacity: 1;
  right: 0;
}

/* Navigation */
.navigation {
  padding: 2%;
}

.logo {
  height: 25%;
  width: 25%;
  margin-top: 2%;
  margin-left: 2%;
}

.nav-links {
  display: flex;
  float: right;
  margin-top: 2%;
  margin-right: 15%;
}

.nav-link {
  color: var(--colour-pink);
  display: inline-block;
  margin-right: 20%;
}

.nav-link::after {
  content: '';
  background: var(--colour-pink);
  display: block;
  height: 2px;
  width: 0;
  margin-top: -15%;
  transition: width .3s;
}

.nav-link:hover::after {
  width: 100%;
}

/* Footer */
.footer {
  background-color: var(--colour-pink);
  text-align: center;
  padding: 5%;
  padding-bottom: 10%;
}

.footer a {
  color: var(--colour-white);
  padding: 5%;
}

.footer a:hover {
  columns: var(--colour-light-pink);
  transition: 0.3s;
}

/* Home */
.home-bg {
  text-align: center;
  padding: 150px 0;
  position: relative;
}

.environment {
  background: url("./images/city-landscape.jpg") center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  filter: blur(5px);
}

.name {
  font-size: clamp(55px, 10vw, 100px);
  line-height: 1;
  display: inline-block;
  color: var(--colour-white);
  z-index: 2;
  letter-spacing: 10px;
  filter: drop-shadow(0 1px 3px);
}

.glitch span {
  animation: paths 5s step-end infinite;
}

.glitch::before {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 8s step-end infinite, movement 10s step-end infinite;
}

.glitch::after {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 7s step-end infinite, movement 8s step-end infinite;
}

.layers {
  position: relative;
}

.layers::before,
.layers::after {
  content: attr(data-text);
  position: absolute;
  width: 110%;
  z-index: -1;
}

.layers::before {
  top: 10px;
  left: 15px;
  color: var(--colour-pink);
}

.layers::after {
  top: 5px;
  left: -10px;
  color: var(--colour-blue);
}

.role {
  letter-spacing: 0.4rem;
}

.indicator {
  border: 2px solid var(--colour-white);
  border-radius: 50px;
  position: relative;
  height: 90px;
  width: 55px;
  margin: auto;
}

.indicator:before {
  content: "";
  background: var(--colour-white);
  border-radius: 50%;
  position: absolute;
  height: 10px;
  width: 10px;
  left: 0; 
  right: 0;
  top: 20px;
  margin: auto;
  animation: animate 1s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 1;
    top: 20px;
  }

  100% {
    opacity: 0;
    top: 75px;
    transform: scale(0.8);
  }
}

.skills,
.tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.skills li,
.tools li {
  border: 0.1rem solid var(--colour-pink);
  border-radius: 0.5rem;
  color: var(--colour-white);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.6;
  margin: 0.5rem;
  padding: 1rem;
  white-space: nowrap;
}

.email {
  color: var(--colour-pink);
}

.email:hover {
  filter: drop-shadow(0 1px 3px);
  transition: 0.3s;
}

/* Portfolio */
.portfolio h1 {
  text-align: center;
}

.project {
  margin-top: 10%;
  margin-bottom: 15%;
}

.project h2 {
  font-family: "Space Mono";
  width: 80%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
}

.project-img {
  border-radius: 5px;
  height: 80%;
  width: 80%;
  display: block;
  margin: auto;
}

.tools {
  width: 84%;
  margin: auto;
  justify-content: left;
}

.tools li {
  font-size: 1.3rem;
  padding: 0.8rem;
}

.description {
  width: 80%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
}

.animated-link {
  text-align: center;
  width: 70%;
  margin-top: 8%;
  padding: 5%;
}

.animated-link:hover {
  color: var(--colour-white);
}