@media (min-width: 768px) {
  /* General */
  p {
    font-size: 1.8rem;
  }

  .page-content {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .animated-btn {
    font-size: 1.8rem;
    width: 60%;
    margin-top: 10%;
    padding: 5%;
  }

  /* Navigation */
  .logo {
    height: 18%;
    width: 18%;
    margin-left: 4%;
  }

  .nav-links {
    margin-top: 4%;
  }

  /* Footer */
  .footer {
    padding: 2%;
    padding-bottom: 5%;
  }

  .footer a {
    padding: 2%;
  }

  /* Portfolio */
  .project {
    margin-top: 8%;
  }

  .project h2 {
    font-size: 2rem;
    margin-bottom: 2%;
  }

  .tools {
    width: 82%;
  }

  .description {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .animated-link {
    width: 50%;
    margin-top: 5%;
  }
}